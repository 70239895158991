<template>
    <b-overlay :show='showLoader'>
        <b-card class="card-traffic mb-0">
            <h1 class="text-secondary font">Razdelilnik</h1>
            <b-row class="m-0 mt-2" align-v="center">
                <b-col cols="12" xl="4" class="mt-1 px-0 px-xl-1">
                    <b-form-group label="Datum od:" label-cols-lg="4" label-cols-md="3" label-cols-sm="3">
                        <b-input-group>
                            <flat-pickr class="form-control" v-model="from" :config="flatpickrConfig" placeholder="Od" @input="loadData"/>
                            <b-input-group-append>
                                <button class="append-button" data-toggle><FeatherIcon icon="ChevronDownIcon"/></button>
                            </b-input-group-append>
                        </b-input-group>
                    </b-form-group>
                </b-col>
                <b-col cols="12" xl="4" class="mt-1 px-0 px-xl-1">
                    <b-form-group label="Datum do:" label-cols-lg="4" label-cols-md="3" label-cols-sm="3">
                        <b-input-group>
                            <flat-pickr class="form-control" v-model="to" :config="flatpickrConfig" placeholder="Do" @input="loadData"/>
                            <b-input-group-append>
                                <button class="append-button" data-toggle><FeatherIcon icon="ChevronDownIcon"/></button>
                            </b-input-group-append>
                        </b-input-group>
                    </b-form-group>
                </b-col>
                <b-col cols="12" xl="4" class="px-0 px-xl-1">
                    <rounded-button @click="loadData">PRIKAŽI <fa icon="search"/></rounded-button>
                </b-col>
            </b-row>
            <my-portal-table class="mt-1" :data="data" :fields="fields">
                <template #cell()="row">
                    <template v-if="row.field.type === 1">
                        {{ dayjs(row.item[row.field.key]).format('DD.MM.YYYY') }}
                    </template>
                    <template v-else-if="row.field.type === 2">
                        {{ row.item['obracun_leto'] }} / {{ row.item['obracun_mesec'] }}-{{ row.item['obracun_vrsta'] }}
                    </template>
                    <template v-else-if="row.field.type === 3">
                        <template v-if="row.item['zaprto']">
                            {{ dayjs(row.item['datum_zadnjega_placila']).format('DD.MM.YYYY') }}
                        </template>
                        <template v-else>
                            /
                        </template>
                    </template>
                    <template v-else>
                        {{ row.item[row.field.key]}}
                    </template>
                </template>
                <template #custom-foot>
                    <th colspan="5"  style="font-weight: 900;">Skupaj</th>
                    <th  class="text-center" style="font-weight: 900 !important;">{{$convertFloat(data.reduce((a, b) => a + b.breme, 0))}} €</th>
                    <th  class="text-center" style="font-weight: 900;">{{$convertFloat(data.reduce((a, b) => a + b.dobro, 0))}} €</th>
                    <th/>
                </template>
                <template #cell(actions)="data">
                    <a v-if="data.item.sklic" @click="downloadRazdelilnik(data.item)">
                        <img width="35px" height="35px" :src="require('@/assets/svg/pdf_icon.svg')"/>
                    </a>
                </template>
            </my-portal-table>
        </b-card>
    </b-overlay>
</template>

<script>
    import flatPickr from 'vue-flatpickr-component'
    import 'flatpickr/dist/flatpickr.css'
    import {Slovenian} from 'flatpickr/dist/l10n/sl.js'
    import {BRow, BCol, BFormGroup, BInputGroup, BInputGroupAppend, BCard, BOverlay} from 'bootstrap-vue'
    import RoundedButton from '@/views/Components/RoundedButton.vue'
    import MyPortalTable from '@/views/Components/MyPortalTable'

    export default {
        components: {
            MyPortalTable,
            BCard,
            flatPickr,
            BRow,
            BCol,
            BFormGroup,
            BInputGroup,
            BInputGroupAppend,
            RoundedButton,
            BOverlay
        },
        data() {
            return {
                from: new Date(),
                to: new Date(),
                flatpickrConfig: { altInput: true, dateFormat: 'Z', altFormat: 'd. m. Y', wrap: true, locale: Slovenian},
                fields: [
                    //{ key: 'sklic', label: 'Sklic', sortable: false, class: 'text-left', type: 0 },
                    { key: 'obracun_leto', label: 'Obračun', sortable: false, class: 'text-left', type: 2 },
                    { key: 'datum_dokumenta', label: 'Datum dokumenta', sortable: false, class: 'text-left', type: 1 },
                    { key: 'datum_valute', label: 'Datum valute', sortable: false, class: 'text-left', type: 1 },
                    { key: 'datum_zadnjega_placila', label: 'Datum plačila', sortable: false, class: 'text-left', type: 3 },
                    { key: 'partner_naziv', label: 'Naziv', sortable: false, class: 'text-left', type: 0 },
                    { key: 'breme', label: 'Breme (€)', sortable: false, class: 'text-left', type: 0 },
                    { key: 'dobro', label: 'Dobro (€)', sortable: false, class: 'text-left', type: 0 },
                    { key: 'actions', label: '', sortable: false, class: 'text-left' }
                ],
                data: [],
                showLoader: false
            }
        },
        methods: {
            loadData() {
                const thisIns = this
                thisIns.showLoader = true

                const from = this.dayjs(this.from).format('YYYY-MM-DD')
                const to = this.dayjs(this.to).format('YYYY-MM-DD')

                thisIns.$http.get(`/api/iiportal-user/v1/e_manager/promet/${from}/${to}`)
                    .then(res => {
                        this.data = res.data
                    })
                    .catch(error => {
                        thisIns.$printError(`Pri nalaganju podatkov je prišlo do napake!\n${error.message}`)
                    }).finally(() => {
                        thisIns.showLoader = false
                    })
            },
            downloadRazdelilnik(data) {
                const thisIns = this
                this.pfdRazdelilnik = true
                this.$http({
                    url: `/api/iiportal-user/v1/attachements/razdelilnik/redni_obracun/${data.sklic}`, //your url
                    method: 'GET',
                    responseType: 'blob' // important
                })
                    .then((response) => {
                        const url = window.URL.createObjectURL(new Blob([response.data]))
                        const link = document.createElement('a')
                        link.href = url
                        link.setAttribute('download', `${data.partner_naziv} ${data.datum_dokumenta}.pdf`) //or any other extension
                        document.body.appendChild(link)
                        link.click()
                    })
                    .catch(error => {
                        thisIns.$printError(`Pri nalaganju pdf je prišlo do napake!\n${error.message}`)
                    }).finally(() => {
                        this.pfdRazdelilnik = false
                    })
            }
        },
        async mounted() {
            const now = new Date()
            this.from = new Date(now.getFullYear(), 0, 1)
            // this.from = new Date(now.getFullYear(), now.getMonth(), 1)
            this.to = new Date()

            await this.loadData()
        }

    }
</script>

<style>
legend{
  font-weight: 900;
}
.card-traffic{
  border-radius: 10px !important;
}
.card-traffic .table tfoot th {
  border-top: 2px solid #aecce9;
  background-color: #eaf3f9 !important;
}
.card-traffic .font {
  font-weight: bold;
}
.card-traffic .font-button {
  font-weight: bold;
  background: #72a5d8 !important;
}
.card-traffic .append-button {
  background-color: #72a5d8;
  border: none;
  color: white
}
.custom-foot{
  background: #eaf3f9 !important;
}
.card-traffic .input-group > .form-control {
  border-top-left-radius: 0px !important;
  border-bottom-left-radius: 0px !important;
  transition: border-color 0.15s ease-in-out !important;
}

</style>
